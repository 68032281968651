@tailwind base;
@tailwind components;
@tailwind utilities;

canvas {
  margin: 0 auto;
  width: 78% !important;
  height: 95% !important;
  /* border-radius: 10px; */
}

.react-video-thumbnail-image img {
  border-top-left-radius: 16px ;
  border-top-right-radius: 16px ;
}

.withFullBorder img {
  border-radius: 24px !important;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}

input {
  background-color: transparent;
  outline: none;
}

input::placeholder {
  color: #171202;
  opacity: 30%;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #2e1a47;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 18px;
  height: 18px;
  background: #2e1a47;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  height: 8px;
  width: 8px;
}

select {
  background-color: transparent;
  text-align: left;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select option {
  padding-left: -5px;
}

.custom-input-style {
  background: url("./assets/search.png") no-repeat scroll 1px 4px;
  background-size: 17px;
  padding-left: 25px;
}

.custom-input-style::placeholder {
  color: #2e1a47;
}

.bottom-line-style::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 2px;
  bottom: 1px;
  left: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.bottom-line-active-style::after {
  content: "";
  position: absolute;
  background-color: #2e1a47;
  width: 100%;
  height: 4px;
  bottom: 0px;
  left: 0;
  border-radius: 10px;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.bottom-line-style-active-input-style::after {
  content: "";
  position: absolute;
  background-color: #4c8bf5;
  width: 100%;
  height: 2px;
  bottom: 1px;
  left: 0;
  bottom: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.backdrop-style {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 10;
}

.custom-dropdown-arrow-style {
  padding-right: 30px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("./assets/arrowDown.png");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1em auto, 100%;
  cursor: pointer;
}

.dashed-border-style {
  border: 2px dashed rgba(46, 26, 71, 0.9);
}

.button-border-style {
  border: 2px solid rgba(0, 0, 0, 0.1);
}

.gray-border-style {
  border: 2px solid #eaeaf6;
}

.custom-switch-style {
  position: relative;
  appearance: none;
  outline: none;
  width: 50px;
  height: 28px;
  background-color: #ffffff;
  border: 1px solid #d9dadc;
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 #ffffff;
  transition-duration: 200ms;
}

.custom-switch-style::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 26px;
  height: 26px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}

.custom-switch-style:checked {
  border-color: #2e1a47;
  box-shadow: inset 20px 0 0 0 #2e1a47;
}

.custom-switch-style:checked::after {
  left: 20px;
  box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}

.custom-table-style {
  @apply border-separate;
  border-spacing: 0 15px;
}

.custom-table-style td {
  border: 1px solid white;
  background-color: white;
  font-size: 14px;
  font-weight: 600;
}

.custom-table-style td:first-child {
  border-left-style: solid;
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.custom-table-style td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 9999px;
  border-top-right-radius: 9999px;
}

.custom-table-menu-style td {
  border: 1px solid #b2bcd3;
}

.custom-table-menu-style td:first-child {
  border-left: none;
}

.custom-table-menu-style td:last-child {
  border-right: none;
}

.custom-table-menu-style-after::after {
  content: "▼";
  font-size: 13px;
  margin-left: 4px;
}

.custom-checkbox-style {
  cursor: pointer;
  position: relative;
}

.custom-checkbox-style > input {
  height: 15px;
  width: 15px;
  appearance: none;
  border: 1px solid #2e1a47;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  cursor: pointer;
}

.custom-checkbox-style > input:checked {
  border: 1px solid #c2f1b7;
  background-color: #c2f1b7;
  color: #2e1a47;
  text-align: center;
  position: relative;
}

.custom-checkbox-style > input::before {
  opacity: 0;
  transition: all ease-in-out 0.3s;
}

.custom-checkbox-style > input:checked::before {
  content: "✓";
  font-size: 10px;
  font-weight: bold;
  color: #2e1a47;
  left: 2px;
  top: -1px;
  position: absolute;
  opacity: 1;
}

/* .custom-checkbox-style > label {
  left: 3px;
  position: absolute;
  color: #2e1a47;
  font-size: 11px;
} */

.custom-input-form-style input:invalid:focus:required {
  border-bottom: 2px solid red;
  border-radius: 5px;
}

.custom-input-form-style-label {
  font-weight: 600;
  position: absolute;
  pointer-events: none;
  left: 0px;
  transition: 300ms ease-in-out all;
}

.custom-input-form-style-label-shrink-active {
  transform: translate(0px, -8px);
  background-color: rgb(255, 255, 255);
  font-size: 11px;
  z-index: 50px;
}

.custom-input-form-style-label-shrink-active:focus {
  color: #4c8bf5;
}

.custom-input-form-style-bigger-label-shrink-active {
  transform: translate(0px, -40px);
  background-color: rgb(255, 255, 255);
  font-size: 16px;
}

.custom-box-shadow-style {
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.15);
}

.custom-bottom-border-style {
  border-bottom: 1px solid rgba(23, 18, 2, 0.1);
}

.custom-checkbox {
  @apply custom-checkbox-style;
}

.custom-input {
  @apply custom-input-style;
}

.bottom-line {
  @apply bottom-line-style;
}

.bottom-line-style-active-input {
  @apply bottom-line-style-active-input-style;
}

.bottom-line-active {
  @apply bottom-line-active-style;
}

.backdrop {
  @apply backdrop-style;
}

.button-border {
  @apply button-border-style;
}

.custom-dropdown-arrow {
  @apply custom-dropdown-arrow-style;
}

.dashed-border {
  @apply dashed-border-style;
}

.gray-border {
  @apply gray-border-style;
}

.custom-switch {
  @apply custom-switch-style;
}

.custom-table {
  @apply custom-table-style;
}

.custom-table-menu {
  @apply custom-table-menu-style;
}

.custom-table-menu-after {
  @apply custom-table-menu-style-after;
}

.custom-input-form {
  @apply custom-input-form-style;
}

.custom-input-form-label {
  @apply custom-input-form-style-label;
}

.custom-input-form-label-shrink-active {
  @apply custom-input-form-style-label-shrink-active;
}

.custom-input-form-bigger-label-shrink-active {
  @apply custom-input-form-style-bigger-label-shrink-active;
}

.custom-box-shadow {
  @apply custom-box-shadow-style;
}

.custom-bottom-border {
  @apply custom-bottom-border-style ;
}

